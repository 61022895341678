package org.hdg.sanaseppa.components

import androidx.compose.animation.core.*
import androidx.compose.foundation.layout.*
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.VerticalDivider
import androidx.compose.runtime.*
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import org.jetbrains.compose.ui.tooling.preview.Preview
import ui.theme.*

@Composable
@Preview
fun UserInput(input: MutableState<String>, chosenLetter: String) {
    val chosenLetterChar = if(chosenLetter.isNotEmpty()) chosenLetter[0] else ' '
    val primaryColor = MaterialTheme.colorScheme.primary
    val infiniteTransition = rememberInfiniteTransition(label = "infinite")
    val alpha by infiniteTransition.animateFloat(
        initialValue = 1f,
        targetValue = 0f,
        animationSpec = infiniteRepeatable(
            animation = tween(1000, easing = LinearEasing),
            repeatMode = RepeatMode.Reverse
        )
    )

    Row(
        modifier = Modifier.fillMaxWidth()
            .height(IntrinsicSize.Min)
            .defaultMinSize(minHeight = 38.dp),
        horizontalArrangement = Arrangement.Center
    ) {
        input.value.forEach { char ->
            Text(
                color = if (char == chosenLetterChar) primaryColor else Color.Unspecified,
                fontSize = 32.sp,
                text = char.toString().uppercase()
            )

        }
        VerticalDivider(thickness = 2.dp, color = primaryColor.copy(alpha = alpha))
    }
}