package org.hdg.sanaseppa.components

import androidx.compose.foundation.layout.*
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.MaterialTheme
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.sp
import androidx.compose.ui.unit.dp
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.ui.text.ParagraphStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.withStyle
import kotlin.text.Typography.bullet

@Composable
fun InfoScreen(stateShowInfo: MutableState<Boolean>) {
    val list = listOf(
        "Päivittäin vaihtuva peli",
        "Keksi sanoja annetuista kirjaimista",
        "Keskikirjainta on pakko käyttää",
        "Sanat perusmuodossa",
        "Voit käyttää samaa kirjainta useamman kerran",
        "Vähintään neljä kirjainta",
        "Ei paikkojen tai maiden nimiä"
    )
    Column(
        Modifier.fillMaxHeight().widthIn(0.dp, 800.dp).padding(16.dp),
    ) {
        Text(fontSize = 24.sp, fontWeight = FontWeight.ExtraBold, text = "Ohjeet")
        Spacer(modifier = Modifier.height(24.dp))
        val paragraphStyle = ParagraphStyle()
        Text(
            buildAnnotatedString {
                list.forEach {
                    withStyle(style = paragraphStyle) {
                        append(bullet)
                        append(" ")
                        append(it)
                    }
                }
            },
            fontSize = 16.sp
        )
        Spacer(modifier = Modifier.height(24.dp))
        Text(
            fontSize = 16.sp,
            text = "Pelissä käytetään Kotimaisten kielten keskuksen Nykysuomen sanalistaa."
        )
        Spacer(modifier = Modifier.weight(0.5f))
        Button(
            modifier = Modifier.fillMaxWidth(),
            onClick = { stateShowInfo.value = false },
            colors = ButtonDefaults.buttonColors(containerColor = MaterialTheme.colorScheme.secondary)
        ) {
            Text("Sulje")
        }
        Spacer(modifier = Modifier.weight(0.05f))
    }
}