package org.hdg.sanaseppa.components

import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyHorizontalGrid
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.*
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.UriHandler
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import org.hdg.sanaseppa.setLocalSave
import ui.theme.primaryDark
import ui.theme.primaryLight

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun ListGuesses(
    gameId: String,
    guessedWords: List<String>,
    allWords: Array<String>,
    stateProgress: Double,
    stateWordOfTheDay: MutableState<String>,
    uriHandler: UriHandler,
    stateGameEnded: MutableState<Boolean>,
    isDarkTheme: Boolean
) {

    val stateShowAlert = remember { mutableStateOf(false) }



        Column(
            Modifier.fillMaxHeight().widthIn(0.dp, 800.dp).padding(start = 16.dp, end = 16.dp, bottom = 16.dp),
        ) {
//        if (stateProgress >= 1.0) {

//        }
            if (stateShowAlert.value) {
                AlertDialog(
                    title = {
                        Text(text = "Haluatko varmasti näyttää vastaukset?")
                    },
                    text = {
                        Text(text = "Tämä lopettaa pelin etkä voi enään syöttää uusia sanoja")
                    },
                    onDismissRequest = { stateShowAlert.value = false },
                    confirmButton = {
                        TextButton(
                            onClick = {
                                stateShowAlert.value = false
                                stateGameEnded.value = true
                                setLocalSave("${gameId}GameEnded", "true")
                            }
                        ) {
                            Text("Vahvista")
                        }
                    },
                    dismissButton = {
                        TextButton(
                            onClick = {
                                stateShowAlert.value = false
                            }
                        ) {
                            Text("Peruuta")
                        }
                    }
                )
            }

            LazyHorizontalGrid(
                modifier = Modifier.weight(0.80f).fillMaxWidth(),
                rows = GridCells.Adaptive(minSize = 24.dp),
                verticalArrangement = Arrangement.spacedBy(16.dp),
                horizontalArrangement = Arrangement.spacedBy(64.dp),
                contentPadding = PaddingValues(horizontal = 16.dp, vertical = 16.dp)
            ) {
                if (!stateGameEnded.value) {
                    val sortedGuessedWords = guessedWords.sortedBy { it }
                    items(sortedGuessedWords.size) { item ->
                        Text(
                            text = sortedGuessedWords[item],
                            fontWeight = if (sortedGuessedWords[item] == stateWordOfTheDay.value) FontWeight.ExtraBold else FontWeight.Normal,
                            modifier = Modifier.clickable {
                                uriHandler.openUri("https://www.kielitoimistonsanakirja.fi/#/${sortedGuessedWords[item]}")
                            })
                    }
                } else {
                    val sortedGuessedWords = allWords.sortedBy { it }
                    items(sortedGuessedWords.size) { item ->
                        Text(
                            color = if (guessedWords.contains(sortedGuessedWords[item])) Color.Unspecified else Color.Red,
                            text = sortedGuessedWords[item],
                            fontWeight = if (sortedGuessedWords[item] == stateWordOfTheDay.value) FontWeight.ExtraBold else FontWeight.Normal,
                            modifier = Modifier.clickable {
                                uriHandler.openUri("https://www.kielitoimistonsanakirja.fi/#/${sortedGuessedWords[item]}")
                            })
                    }
                }
            }
            Spacer(modifier = Modifier.height(16.dp))
            Row(Modifier.fillMaxWidth()) {
                Text(
                    text = "Sanat: ${guessedWords.size}/${allWords.size}"
                )
                Spacer(modifier = Modifier.weight(0.85f))
                if (!stateGameEnded.value) {
                    Text(
                        color = MaterialTheme.colorScheme.secondary,
                        text = "Näytä vastaukset",
                        fontWeight = FontWeight.Bold,
                        modifier = Modifier.clickable {
                            stateShowAlert.value = true
                        }
                    )
                }
            }
            Spacer(modifier = Modifier.height(16.dp))
            Text("Klikkaamalla sanaa voit avata Kielitoimiston sanakirjan")
            Spacer(modifier = Modifier.weight(0.01f))
        }

}