package org.hdg.sanaseppa.components

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowDropDown
import androidx.compose.material.icons.filled.Close
import androidx.compose.material3.*
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.text.withStyle
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp

@Composable
fun ButtonGuesses(
    stateAllGuessesVisible: MutableState<Boolean>,
    guessedWords: List<String>,
    stateWordOfTheDay: MutableState<String>
) {
    Row(
        horizontalArrangement = Arrangement.spacedBy(8.dp),
        modifier = Modifier.widthIn(0.dp, 800.dp).padding(start =  16.dp, end = 16.dp),
    ) {
        Button(
            shape = RoundedCornerShape(8.dp),
            onClick = { stateAllGuessesVisible.value = !stateAllGuessesVisible.value },
            modifier = Modifier.fillMaxWidth(),
            colors = ButtonDefaults.buttonColors(containerColor = MaterialTheme.colorScheme.secondary)
        ) {
            AnimatedVisibility(!stateAllGuessesVisible.value) {
                val text = guessedWords.map { it }.reversed().joinToString(separator = ", ")
                Text(
                    fontSize = 16.sp,
                    textAlign = TextAlign.Left,
                    modifier = Modifier.fillMaxWidth(0.9f),
                    maxLines = 1,
                    overflow = TextOverflow.Ellipsis,
                    text = buildAnnotatedString {
                        val words = text.split(", ")
                        words.forEachIndexed { index, word ->
                            if (word == stateWordOfTheDay.value) {
                                withStyle(style = SpanStyle(fontWeight = FontWeight.ExtraBold)) {
                                    append(word)
                                }
                            } else {
                                append(word)
                            }
                            if (index < words.size - 1) {
                                append(", ")
                            }
                        }
                    }
                )
            }
            Icon(
                imageVector = if (stateAllGuessesVisible.value) Icons.Filled.Close else Icons.Filled.ArrowDropDown,
                contentDescription = "avaa"
            )
        }
    }
}