package ui.theme

import androidx.compose.foundation.isSystemInDarkTheme
import androidx.compose.runtime.Composable
import androidx.compose.ui.graphics.Color

val primaryLight = Color(0xffff6060)
val onPrimaryLight = Color(0xFFFFFFFF)
val primaryContainerLight = Color(0xFFFFDBCA)
val onPrimaryContainerLight = Color(0xFF331100)
val secondaryLight = Color(0xff403232)
val onSecondaryLight = Color(0xFFFFFFFF)
val secondaryContainerLight = Color(0xffffbbaf)
val onSecondaryContainerLight = Color(0xFF2B160A)
val tertiaryLight = Color(0xFF646032)
val onTertiaryLight = Color(0xFFFFFFFF)
val tertiaryContainerLight = Color(0xFFEBE4AA)
val onTertiaryContainerLight = Color(0xFF1F1C00)
val errorLight = Color(0xFFBA1A1A)
val onErrorLight = Color(0xFFFFFFFF)
val errorContainerLight = Color(0xFFFFDAD6)
val onErrorContainerLight = Color(0xFF410002)
val backgroundLight = Color(0xffff6060)
val onBackgroundLight = Color(0xFF221A15)
val surfaceLight = Color(0xFFFFF8F6)
val onSurfaceLight = Color(0xFF221A15)
val surfaceVariantLight = Color(0xfffdf3f3) // This is for web
val onSurfaceVariantLight = Color(0xFF52443D)
val outlineLight = Color(0xFF85736B)
val outlineVariantLight = Color(0xFFD7C2B9)
val scrimLight = Color(0xFF000000)
val inverseSurfaceLight = Color(0xFF382E2A)
val inverseOnSurfaceLight = Color(0xFFFFEDE6)
val inversePrimaryLight = Color(0xFFFFB690)
val surfaceDimLight = Color(0xFFE8D7CF)
val surfaceBrightLight = Color(0xFFFFF8F6)
val surfaceContainerLowestLight = Color(0xFFFFFFFF)
val surfaceContainerLowLight = Color(0xFFFFF1EB)
val surfaceContainerLight = Color(0xFFFCEAE3)
val surfaceContainerHighLight = Color(0xFFF6E5DD)
val surfaceContainerHighestLight = Color(0xfffdf3f3) // This is for App

val primaryDark = Color(0xffff6060)
val onPrimaryDark = Color(0xff371701)
val primaryContainerDark = Color(0xFF703715)
val onPrimaryContainerDark = Color(0xFFFFDBCA)
val secondaryDark = Color(0xffff6060)
val onSecondaryDark = Color(0xFF432B1D)
val secondaryContainerDark = Color(0xff5e4d49)
val onSecondaryContainerDark = Color(0xFFFFDBCA)
val tertiaryDark = Color(0xFFCEC890)
val onTertiaryDark = Color(0xFF343108)
val tertiaryContainerDark = Color(0xFF4C481C)
val onTertiaryContainerDark = Color(0xFFEBE4AA)
val errorDark = Color(0xFFFFB4AB)
val onErrorDark = Color(0xFF690005)
val errorContainerDark = Color(0xFF93000A)
val onErrorContainerDark = Color(0xFFFFDAD6)
val backgroundDark = Color(0xff171414)
val onBackgroundDark = Color(0xFFF0DFD8)
val surfaceDark = Color(0xFF1A120E)
val onSurfaceDark = Color(0xFFF0DFD8)
val surfaceVariantDark = Color(0xff2e2523)  // This is for web
val onSurfaceVariantDark = Color(0xFFD7C2B9)
val outlineDark = Color(0xFFA08D84)
val outlineVariantDark = Color(0xFF52443D)
val scrimDark = Color(0xFF000000)
val inverseSurfaceDark = Color(0xFFF0DFD8)
val inverseOnSurfaceDark = Color(0xFF382E2A)
val inversePrimaryDark = Color(0xFF8D4E2A)
val surfaceDimDark = Color(0xFF1A120E)
val surfaceBrightDark = Color(0xFF413732)
val surfaceContainerLowestDark = Color(0xFF140C09)
val surfaceContainerLowDark = Color(0xFF221A15)
val surfaceContainerDark = Color(0xFF271E19)
val surfaceContainerHighDark = Color(0xFF322823)
val surfaceContainerHighestDark = Color(0xff2e2523) // This is for App