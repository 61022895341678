package org.hdg.sanaseppa

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.unit.dp
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.hdg.sanaseppa.api.fetchGameData
import org.jetbrains.compose.ui.tooling.preview.Preview
import ui.theme.*
import kotlinx.io.IOException
import org.hdg.sanaseppa.components.*

@Composable
@Preview
fun App() {

    val pointsToBeatGame = 0.5

    val guessedWords = remember { mutableStateOf(emptyList<String>()) }

    val scope = rememberCoroutineScope()

    val stateGameId = remember { mutableStateOf("") }
    val stateWordOfTheDay = remember { mutableStateOf("") }
    val stateChosenGameLetter = remember { mutableStateOf("") }
    val stateGameLetters = remember { mutableStateOf(arrayOf("", "", "", "", "", "", "", "")) }
    val stateGameWords = remember { mutableStateOf(arrayOf("")) }
    val stateGameEnded = remember { mutableStateOf(false) }

    val stateUserInput = remember { mutableStateOf("") }

    val totalPoints = guessedWords.value.sumOf { it.length }
    val lastPoints = remember { mutableStateOf(totalPoints) }

    val stateInputResponse = remember { mutableStateOf("") }
    val stateInputResponseShow = remember { mutableStateOf(false) }

    val stateAllGuessesVisible = remember { mutableStateOf(false) }

    val stateErrorVisible = remember { SnackbarHostState() }

    val uriHandler = LocalUriHandler.current

    val isDarkTheme = remember { mutableStateOf(false) }

    val stateShowInfo = remember { mutableStateOf(false) }

    val stateShowWinDialog = remember { mutableStateOf(false) }

    val stateShowLoading = remember { mutableStateOf(true) }

    suspend fun getInitialData() {
        try {
            val localIsDarkTheme = getLocalSave("DarkTheme")
            if (localIsDarkTheme.isNotEmpty()) {
                isDarkTheme.value = localIsDarkTheme.toBoolean()
            }
            val gameData = fetchGameData()
            if (gameData == null) {
                scope.launch {
                    val result = stateErrorVisible
                        .showSnackbar(
                            message = "Verkkovirhe",
                            actionLabel = "Yritä uudelleen",
                            duration = SnackbarDuration.Indefinite
                        )
                    when (result) {
                        SnackbarResult.ActionPerformed -> {
                            getInitialData()
                        }

                        SnackbarResult.Dismissed -> {

                        }
                    }
                }
                return
            }

            stateWordOfTheDay.value = gameData.game.word
            stateChosenGameLetter.value = gameData.game.chosenLetter
            stateGameLetters.value = gameData.game.letters.filter { it != gameData.game.chosenLetter }.toTypedArray()
            stateGameLetters.value.shuffle()
            stateGameWords.value = gameData.game.allWords.toTypedArray()
            stateGameId.value = gameData.game.id

            val localSave = getLocalSave(stateGameId.value)
            if (localSave.isNotEmpty()) {
                guessedWords.value = localSave.split(",")
            }
            lastPoints.value = guessedWords.value.sumOf { it.length }

            val tipSeen = getLocalSave("RulesSeen")
            if (tipSeen.isEmpty() || tipSeen == "false") {
                setLocalSave("RulesSeen", "true")
                stateShowInfo.value = true
            }

            val gameEnded = getLocalSave("${stateGameId.value}GameEnded")
            if (gameEnded.isNotEmpty()) {
                stateGameEnded.value = true
            }
            stateShowLoading.value = false
        } catch (error: IOException) {
            scope.launch {
                val result = stateErrorVisible
                    .showSnackbar(
                        message = "Verkkovirhe",
                        actionLabel = "Yritä uudelleen",
                        duration = SnackbarDuration.Indefinite
                    )
                when (result) {
                    SnackbarResult.ActionPerformed -> {
                        getInitialData()
                    }

                    SnackbarResult.Dismissed -> {

                    }
                }
            }
        }
    }

    LaunchedEffect(key1 = "start") {
        getInitialData()
    }

    val totalCharacters = stateGameWords.value.sumOf { it.length }
    val stateProgress =
        (if (totalCharacters != 0) (totalPoints).toFloat() else 0.0).toFloat() / (totalCharacters.toFloat() * pointsToBeatGame)

    LaunchedEffect(key1 = stateInputResponseShow.value) {
        delay(1900)
        stateInputResponseShow.value = false
    }
    AppTheme(useDarkTheme = isDarkTheme.value) {
        Scaffold(snackbarHost = {
            SnackbarHost(hostState = stateErrorVisible)
        }) {
            Column(
                Modifier.fillMaxSize(),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                AnimatedVisibility(stateShowLoading.value) {
                    LoadingScreen()
                }
                AnimatedVisibility(stateShowWinDialog.value) {
                    DialogWin(stateShowWinDialog)
                }
                Row(
                    horizontalArrangement = Arrangement.End,
                    modifier = Modifier.fillMaxWidth().padding(start = 16.dp, end = 16.dp, top = 16.dp)
                ) {
                    ButtonHelp {
                        stateShowInfo.value = !stateShowInfo.value
                    }
                    Spacer(modifier = Modifier.width(8.dp))
                    DarkThemeSwitch({
                        isDarkTheme.value = !isDarkTheme.value
                        setLocalSave("DarkTheme", isDarkTheme.value.toString())
                    })
                }
                AnimatedVisibility(stateShowInfo.value) {
                    InfoScreen(stateShowInfo)
                }
                GameProgress(
                    stateProgress.toFloat(),
                    isDarkTheme.value,
                    totalPoints,
                    (totalCharacters * pointsToBeatGame).toInt()
                )
                ButtonGuesses(stateAllGuessesVisible, guessedWords.value, stateWordOfTheDay)
                AnimatedVisibility(stateAllGuessesVisible.value) {
                    ListGuesses(
                        stateGameId.value,
                        guessedWords.value,
                        stateGameWords.value,
                        stateProgress,
                        stateWordOfTheDay,
                        uriHandler,
                        stateGameEnded,
                        isDarkTheme.value
                    )
                }
                Spacer(modifier = Modifier.height(32.dp))
                Card(
                    Modifier.fillMaxHeight().widthIn(0.dp, 800.dp),
                    shape = RoundedCornerShape(topStart = 40.dp, topEnd = 40.dp),
                ) {
                    Column(
                        modifier = Modifier.fillMaxSize().padding(16.dp),
                        horizontalAlignment = Alignment.CenterHorizontally,
                        verticalArrangement = Arrangement.Center
                    ) {
                        Row(
                            modifier = Modifier.fillMaxWidth()
                                .height(IntrinsicSize.Min)
                                .defaultMinSize(minHeight = 50.dp),
                            horizontalArrangement = Arrangement.Center
                        ) {
                            AnimatedVisibility(stateInputResponseShow.value) {
                                SuggestionChip(
                                    onClick = {},
                                    label = { Text(stateInputResponse.value) }
                                )
                            }
                        }
                        UserInput(stateUserInput, stateChosenGameLetter.value)
                        Spacer(modifier = Modifier.height(24.dp))
                        InputButtons(stateUserInput, stateGameLetters, stateChosenGameLetter)
                        Spacer(modifier = Modifier.height(24.dp))
                        BottomInputButtons(
                            stateGameId,
                            stateUserInput,
                            stateGameLetters,
                            stateInputResponse,
                            stateInputResponseShow,
                            guessedWords,
                            stateChosenGameLetter,
                            stateGameWords,
                            stateWordOfTheDay,
                            stateGameEnded.value,
                            stateShowWinDialog,
                            totalPoints,
                            lastPoints,
                            (totalCharacters * pointsToBeatGame).toInt()
                        )
                    }
                }

            }
        }
    }
}