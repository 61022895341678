package org.hdg.sanaseppa.components

import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.LinearProgressIndicator
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.ui.tooling.preview.Preview
import ui.theme.primaryDark
import ui.theme.primaryLight

@Composable
@Preview
fun GameProgress(stateProgress: Float, isDarkTheme: Boolean, guessedWordsCount: Number, allWordsCount: Number) {
    Column(
        Modifier
            .widthIn(0.dp, 800.dp),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Text(
            text = "Pisteet: ${if (allWordsCount != 0) guessedWordsCount else 0}/$allWordsCount",
        )
    }
    Row(
        horizontalArrangement = Arrangement.spacedBy(8.dp),
        modifier = Modifier.widthIn(0.dp, 800.dp).padding(start = 16.dp, end = 16.dp),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Text(
            text = "${((stateProgress * 100).toInt().toString())}%",
            modifier = Modifier.width(48.dp)
        )
        LinearProgressIndicator(
            progress = { if (stateProgress.isNaN()) 0.0F else stateProgress },
            color = MaterialTheme.colorScheme.secondary,
            modifier = Modifier
                .height(8.dp)
                .fillMaxWidth()
                .clip(RoundedCornerShape(16.dp))
        )
    }
}